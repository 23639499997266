import React, { useState } from "react"
import { motion } from "framer-motion"
import Img from "gatsby-image"

const CouncilListItem = ({ url, logo, title, className }) => {
  const [scale, setScale] = useState(1)

  return (
    <motion.a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={` w-48 flex flex-col justify-start text-center items-center ${className}`}
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: scale }}
      transition={{ duration: 0.2 }}
      onMouseOver={() => setScale(1.05)}
      onMouseOut={() => setScale(1)}
    >
      {/* {council.node.title} */}
      <div className="h-48 w-48 rounded-full grid content-center justify-center overflow-hidden bg-white -mb-3">
        <Img fixed={logo} title={title} alt={`${title} logo`} />
      </div>
      <div className="bg-white text-green-500 font-bold p-3 rounded-md">
        {title}
      </div>
    </motion.a>
  )
}

export default CouncilListItem
