import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/SEO"
import { FaFacebook } from "react-icons/fa"

import Layout from "../components/layout"
// import calendarIcon from "../images/calendar.svg"
// import pinIcon from "../images/mapPin.svg"
// import IndexButton from "../components/index/indexButton"
import CenterContainer from "../components/index/CenterContainer"
import BlockContentPrimary from "../components/BlockContentPrimary"
import { ConferenceContext } from "../context/conferenceContext"
import CouncilListItem from "../components/index/CouncilListItem"
// import { Helmet } from "react-helmet"
// import FacebookFeed from "../components/FacebookFeed"
// import NewsTile2 from "../components/news/newsTile2"
import BlogCard from "../components/news/BlogCard"
import { SubTitle } from "../components/SiteComponents"
import VideoPlayer from "../components/VideoPlayer"

import { addDateToSortByField, sortPostByDate } from "../helperFunctions"
// import VRBlock from "../components/vr/VRBlock"

// const DateLocationItem = ({ iconSrc, iconAlt, textContent }) => (
//   <div className="dateLocation grid grid-flow-col justify-start items-center col-gap-3 leading-normal md:leading-relaxed">
//     <img src={iconSrc} alt={iconAlt} className="h-8" />
//     <h3 className="text-3xl  leading-8">{textContent}</h3>
//   </div>
// )

const IndexPage = ({ data }) => {
  const { fullDate } = useContext(ConferenceContext)
  const { edges: councilList } = data.councils
  const rawDescription = data.homePage._rawDescription

  // --- Sort News Posts ----///
  const newsPosts = data.allSanityPost.edges
  // Create a deep copy of the array without references using JSON methods
  // https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/
  const copyOfNewsPosts = JSON.parse(JSON.stringify(newsPosts))
  // Add a new field to post object to sort by
  const arrayWithNewDateField = addDateToSortByField(copyOfNewsPosts)
  // Sort by the new date field
  const sortedPosts = sortPostByDate(arrayWithNewDateField)

  // --- End Sort News Posts ----///

  // sort list alphabetically
  councilList.sort((a, b) => {
    let nameA = a.node.title.trim().toUpperCase()
    let nameB = b.node.title.trim().toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  const tempImage = data.placeHolderLogo.childImageSharp.fixed

  const backgroundFluidImageStack = [
    data.lakeView.childImageSharp.fluid,
    `linear-gradient(rgba(var(--nofnec-mediumgreen), 0.5), rgba(var(--nofnec-mediumgreen), 0.5))`,
  ].reverse()

  const backgroundFluidImageStack2 = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(var(--nofnec-mediumgreen), 0.6), rgba(var(--nofnec-mediumgreen), 0.6))`,
  ].reverse()
  //Background image courtesy of Brady Bellini on Unsplash
  //unsplash.com/@brady_bellini?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText
  return (
    <Layout>
      <div className="index__container">
        <SEO title="Home">
          <div id="fb-root"></div>
          <script
            async
            defer
            crossorigin="anonymous"
            src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v8.0&appId=1003338746797858"
            nonce="EpS3Ka69"
          ></script>
        </SEO>

        <BackgroundImage
          Tag="section"
          fluid={backgroundFluidImageStack2}
          className=" flex flex-col content-around pb-64 text-gray-200  items-center z-0"
        >
          <div className="welcome__container__inner flex-col mt-32 md:mt-28 mx-5 md:w-11/12  bg-opacity-25 rounded-lg items-center justify-center content-center">
            <h1 className="mb-5 md:mb-0 text-center tracking-wide text-3xl xs:text-4xl sm:text-5xl md:text-6xl xl:text-7xl 2xl:text-8xl leading-tight">
              Northern Ontario First Nations
              <br className="hidden xs:inline-block" /> Environment Conference
            </h1>

            {/* <article className="welcome__dateLocation__container p-6 flex rounded-lg">

              <div className="flex  flex-col row-gap-3 ">
                <DateLocationItem
                  iconSrc={calendarIcon}
                  iconAlt="Calendar icon"
                  textContent={fullDate}
                />
                <DateLocationItem
                  iconSrc={pinIcon}
                  iconAlt="Pin icon"
                  textContent="Nor'Wester Hotel & Converence Centre"
                />
              </div>
             
            </article> */}

            {/* Registration info button temporarily disabled */}
            {/* <IndexButton text="Registration Info" /> */}
          </div>
          <div className="mx-10 md:w-2/3 pageContent md:text-justify md:p-6 mb-20 ">
            {/* <div className="pSmall sm:text-lg md:text-xl lg:text-2xl"> */}
            <div className="smallText md:medText lg:lgText ">
              <BlockContentPrimary blockData={rawDescription} />
            </div>
            <div className="flex justify-center mt-20">
              <VideoPlayer url="https://youtu.be/65raScqzB5c" />
            </div>
          </div>
        </BackgroundImage>
        <section className="flex justify-center sm:p-8">
          {/* "You should attend nofnec if you are:" Center container */}
          <CenterContainer />
        </section>

        {/* committee container */}

        <BackgroundImage
          fluid={backgroundFluidImageStack}
          className="index__bottom-spacer flex flex-col items-center"
        >
          {/* separator */}
          <div className="h-56" />
          <div className="text-gray-300 w-4/5 my-5">
            <SubTitle className="text-white">Hosted By:</SubTitle>
            <div className="flex flex-wrap gap-12 justify-center mt-4">
              {councilList.map((council, index) => {
                const councilName = council.node.title
                {
                  /* assign logo if a logo record exists for item */
                }
                const councilLogo = council.node.logo
                  ? council.node.logo.asset.fixed
                  : tempImage

                const councilUrl = council.node.url || "#"

                const councilTitle = council.node.title

                return (
                  <CouncilListItem
                    url={councilUrl}
                    logo={councilLogo}
                    title={councilTitle}
                    key={`${councilName}-${index}`}
                    className="mb-5 md:mr-8"
                  />
                )
              })}
            </div>
          </div>
        </BackgroundImage>
        {/* LATEST NEWS removed in 2024 - moved to archived directory */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    homePage: sanityHome {
      _rawDescription
      title
    }
    desktop: file(relativePath: { eq: "s-mazzikim-ux7wtHUIxNQ-unsplash.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 2000, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lakeView: file(relativePath: { eq: "lakePlaceholder.jpg" }) {
      childImageSharp {
        fluid(grayscale: true, quality: 50, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    councils: allSanityParticipant(filter: { active: { eq: true } }) {
      edges {
        node {
          title
          url
          logo {
            asset {
              fixed(height: 150, width: 150) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
    placeHolderLogo: file(relativePath: { eq: "NOFNEC_2020.png" }) {
      id
      childImageSharp {
        fixed(fit: INSIDE, width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allSanityPost(limit: 5) {
      edges {
        node {
          id
          ...BlogCard
        }
      }
    }
  }
`

export default IndexPage
