import React from "react"

import ListItem from "./listItem"
import IndexButton from "./indexButton"

const FirstLetter = ({ children }) => {
  return (
    <span className="conference__explanation-title-color md:text-6xl">
      {children}
    </span>
  )
}

const IndexTitle = ({ children }) => (
  <h1 className="text-center conference__explanation-title tracking-wide text-4xl sm:text-5xl leading-tight pb-3">
    {children}
  </h1>
)

const typesOfAttendees = [
  "First Nation Environment professional",
  "First Nation Land Use Planning professional",
  "First Nation Resource Developer",
  "First Nation Operations and Maintenance professional",
  "Chief or Council Member",
  "First Nation Tribal Council Technical and Advisory Program Staff",
  "Government representative and/or funder",
  "Industry representative",
]

const reasonsToAttend = [
  "An excellent learning/training opportunity",
  "An invaluable networking opportunity",
  "A chance to learn new skills and develop new ideas",
]

const FullList = ({ list }) => {
  return (
    <ul className="pl-5 font-bold text-xl md:text-2xl list-disc">
      {list.map((item, index) => (
        <ListItem key={`FullList-${index}`}>{item}</ListItem>
      ))}
    </ul>
  )
}

// hide inline text on mobile
// const InlineMd = ({ children }) => (
//   <span className="hidden md:inline">{children}</span>
// )
const CenterContainer = () => {
  return (
    <section className="flex justify-center">
      <div className="z-10 bg-white px-5 sm:px-10 md:px-20 py-8 md:py-12 -mt-56 -mb-56 md:w-10/12">
        {/* Container Title */}
        <IndexTitle>
          <FirstLetter>N</FirstLetter>orthern <FirstLetter>O</FirstLetter>
          ntario&nbsp; <FirstLetter>F</FirstLetter>irst&nbsp;{" "}
          <FirstLetter>N</FirstLetter>ations&nbsp;
          <FirstLetter>E</FirstLetter>
          nvironment&nbsp; <FirstLetter>C</FirstLetter>onference&nbsp;is
        </IndexTitle>

        <ul className="conference__list">
          <FullList list={reasonsToAttend} />
        </ul>
        <div className="mt-5">
          <IndexTitle>
            YOU SHOULD ATTEND <FirstLetter>NOFNEC</FirstLetter> IF&nbsp;YOU
            ARE&nbsp;A
          </IndexTitle>
        </div>

        <ul className="conference__list">
          <FullList list={typesOfAttendees} />
        </ul>
        <IndexButton text="About Us" classes="justify-center" url="/about" />
      </div>
    </section>
  )
}

export default CenterContainer
