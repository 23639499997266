import React from "react"
import Img from "gatsby-image"
import { ButtonLinkInt } from "../Button"
import { graphql } from "gatsby"

// import ButtonLink from "../ButtonLink"

export default ({
  fluid = null,
  text = "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web de- signs. The passage is attributed to an unknown.....",
  title = "Post Title",
  handle = "#",
  date = null,
  blogIndex = null,
}) => {
  const colors = ["nofnecGreen", "nofnecRed", "black"]
  return (
    <div className="flex flex-col lg:flex-row bg-white shadow-xl max-w-6xl">
      {/* Only render an image if one is provided in CMS */}
      {fluid && <Img className="lg:w-1/2" fluid={fluid} />}
      <div className="grid p-5 content-between justify-items-start flex-grow">
        <div>
          <h3 className="font-heading text-4xl text-center md:text-left">
            {title}
          </h3>
          {date ? (
            <div className="mb-2 italic text-center md:text-left">{date}</div>
          ) : null}
          <p className="font-heading text-lg lg:mr-32">{text}</p>
        </div>

        <ButtonLinkInt
          to={`/news/${handle}`}
          text="Continue Reading"
          className=" text-white bg-nofnecGreen hover:bg-white hover:text-nofnecGreen border-2 border-nofnecGreen text-xl justify-self-center md:justify-self-end rounded "
          color={colors[blogIndex % 3]}
        />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment BlogCard on SanityPost {
    title
    summary
    slug {
      current
    }
    _createdAt
    publishedAt
    mainImage {
      asset {
        fluid(maxWidth: 1000) {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
`
