import React from "react"
import imageUrlBuilder from "@sanity/image-url"
import mySanityClient from "./sanityClient"

const builder = imageUrlBuilder(mySanityClient)
//  Accepts either a Sanity image record, an asset record, or just the asset id as a string.
// https://www.npmjs.com/package/@sanity/image-url
export const urlFor = source => {
  console.log("helper function is running!")

  return builder.image(source)
}

// Takes in the array of news posts and sorts them either by the createdAt date or by the published Date if it's provided

export const addDateToSortByField = posts => {
  return posts.map(({ node: post }) => {
    let createdAtDate = new Date(post._createdAt)

    let editableDate

    if (post.publishedAt) {
      editableDate = new Date(post.publishedAt)
    } else {
      editableDate = null
    }

    post.dateToSortBy = editableDate || createdAtDate
    post.dateString = `${post.dateToSortBy.getDate()}-${
      monthArray[post.dateToSortBy.getMonth()]
    }-${post.dateToSortBy.getFullYear()}`

    return post
  })
}

export const sortPostByDate = posts => {
  return posts.sort((a, b) => b.dateToSortBy - a.dateToSortBy)
}

export const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
