import React from "react"
import { Link } from "gatsby"

const IndexButton = ({ text = "Link", classes = "", url = "#" }) => {
  return (
    <div
      className={`grid justify-center text-white font-bold text-2xl uppercase tracking-wider ${classes} mt-6`}
    >
      <div className="flex">
        <div className="conference__register__check">&#10004;</div>
        <Link
          to={url}
          className="no-underline bg-red-600 flex items-center px-5 hover:bg-red-500"
        >
          {text}
        </Link>
      </div>
    </div>
  )
}

export default IndexButton
